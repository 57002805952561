<template>
  <v-layout wrap class="justify-center">
    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <v-breadcrumbs
            style="padding-left: 0px; padding-top: 5px; padding-bottom: 5px;"
            :items="breadcrumbs"
            divider="/"
          ></v-breadcrumbs>

        </v-layout>
        <v-layout>
          <page-title>Model users</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text outlined small @click="refresh()">
              <v-icon small class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>
          </div>
        </v-layout>

        <v-layout class="mt-4">
          <p class="secondary--text caption">View users within your project and their balances.
          </p>
        </v-layout>

        <!-- Error message -->
        <v-layout>
          <div v-if="error">
            <v-alert type="error" dense outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-layout>        

        <v-layout class="mt-2">
          <div class="tbcard">
            <v-card>
              <v-data-table v-model="selected" :key="tableKey" :headers="headers" :items="items"
                hide-default-footer :search="e3" :loading="loading" :server-items-length="pagination.totalItems"
                loading-text="Loading... Please wait">

                <template v-if="selected" v-slot:top>
                  <v-row>
                    <v-layout wrap class="justify-end mt-5 mr-5 ml-5">

                      <!-- <v-flex lg2 md5 sm12 height="25px">
                        <v-select class="mr-2" outlined dense v-model="status" :items="statuses" item-text="text"
                          item-value="value" label="Filter by status">
                        </v-select>
                      </v-flex> -->

                      <!-- <v-flex lg2 md2 sm12 class="mr-4">

                        <v-btn small height="40px" outlined class="secondary--text"
                          :disabled="selected && selected.length === 0" @click="deleteSelected()">
                          <v-icon small class="mr-1">delete</v-icon>
                          Delete Selected
                        </v-btn>
                      </v-flex> -->

                    </v-layout>
                  </v-row>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.createdAt | ago }}
                      </span>
                    </template>
                    <span>{{ item.createdAt | date }}</span>
                  </v-tooltip>
                </template>

                {{/* */ }}

                <template v-slot:[`item.input`]="{ item }">
                  <v-tooltip max-width="350px" top :key="`body-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        
                                                  
                      </span>
                    </template>
                    <span>{{ item.id }}</span>
                  </v-tooltip>

                  <v-btn small icon class="secondary--text">
                    <v-icon small @click="copyToClipboard(item.id)">
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>          
           

                <template v-slot:[`item.cost`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.balance.toFixed(4) }}
                      </span>
                    </template>
                    <span>{{ item.balance }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu class="items" top left transition="v-slide-y-transition">
                    <template v-slot:activator="{ on: on }">
                      <v-btn icon v-on="on" :class="
                        $store.state.theme === 'light' ? 'secondary--text' : ''
                      ">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-title>delete {{ item.id }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>adjust balance</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="pr-2 pb-2">
                <v-layout class="mt-2">
                  <v-spacer></v-spacer>
                  <span class="text-caption mt-3 mr-4">Rows per page:</span>
                  <div class="rows-select">
                    <v-flex shrink class="select">
                      <v-select class="mb-2" width="25" v-model="itemsPerPage" :items="itemsPerPageOptions" dense
                        label="Rows per page" single-line></v-select>
                    </v-flex>
                  </div>
                  <span class="text-caption mt-3 mr-4 ml-4">Total items: {{ pagination.totalItems }}</span>
                  <v-btn class="mr-2 mt-2" icon small router :disabled="pagination.previousPageToken == '' ? true : false"
                    :to="{
                      name: 'users',
                      params: {},
                      query: {
                        pageToken: pagination.previousPageToken,
                      },
                    }">
                    <v-icon>navigate_before</v-icon>
                  </v-btn>
                  <!-- Navigate to the next page based on the token -->
                  <v-btn class="ml-2 mt-2" icon small router :disabled="pagination.nextPageToken == '' ? true : false"
                    :to="{
                      name: 'users',
                      params: {},
                      query: {
                        pageToken: pagination.nextPageToken,
                      },
                    }">
                    <v-icon>navigate_next</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageTitle from '../PageTitle';

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      modal: false,
      deletingGen: {
        id: ''
      },
      selected: [],
      itemsPerPage: 10,
      itemsPerPageOptions: [
        10,
        20,
        200,
        500,
      ],      
      status: '',
      statuses: [
        { text: 'Pending', value: 'pending' },
        { text: 'Processing', value: 'processing' },
        { text: 'Completed', value: 'completed' },
        { text: 'Failed', value: 'failed' },
      ]
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        console.log('items per page changed')
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetImageGensPageSize', this.itemsPerPage).then(() => {
          // if (reset && this.pageTokenFromUrl) {
            // this.$router.push({ name: 'queue' }).catch(() => { })
          // } else {
        if (reset) {
          this.loadItems()
        }
        })
      }
    },
    status: {
      handler() {
        this.loadItems()
      }
    },
    $route() {
      this.loadItems()
    }
  },

  computed: {
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    loading() {
      return this.$store.state.endUser.loading
    },
    error() {
      return this.$store.state.endUser.error
    },
    items() {
      return this.$store.state.endUser.users
    },    
    headers() {
      return [
        { text: 'Created At', align: 'left', value: 'createdAt', sortable: true },
        { text: 'User', value: 'id', align: 'left' },
        { text: 'Balance', value: 'balance', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' } 
      ]
    },
    pagination() {
      return this.$store.state.endUser.pagination
    },
    breadcrumbs() {      
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: 'End users',
          disabled: true,
          href: '',
        },
      ]
    }
  },

  async mounted() {
    this.itemsPerPage = this.pagination.pageSize

    this.refresh()
  },

  methods: {
    async refresh() {
      // Loading user for the project
      await this.$store.dispatch('GetUser')

      await this.loadItems()

      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    },
    // Loads etiher text completions or image gens
    async loadItems() {
      await this.fetchEndUsers()      
    },
    // Fetch generated images
    async fetchEndUsers() {
      if (!this.project) {
        return
      }

      let q = {
        projectId: this.project.id,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
      }

      await this.$store.dispatch('ListEndUsers', q)
    },
    copyToClipboard(value) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(value)
    }
  }
}
</script>
