var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"justify-center",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","pr-2":""}},[_c('v-container',{attrs:{"container--fluid":""}},[_c('v-layout',[_c('v-breadcrumbs',{staticStyle:{"padding-left":"0px","padding-top":"5px","padding-bottom":"5px"},attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_c('v-layout',[_c('page-title',[_vm._v("Model users")]),_c('div',{staticClass:"ml-4 mt-1 mb-2"},[_c('v-btn',{staticClass:"secondary--text mt-2 mr-3",attrs:{"text":"","outlined":"","small":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("refresh")]),_vm._v(" Refresh ")],1)],1)],1),_c('v-layout',{staticClass:"mt-4"},[_c('p',{staticClass:"secondary--text caption"},[_vm._v("View users within your project and their balances. ")])]),_c('v-layout',[(_vm.error)?_c('div',[_c('v-alert',{attrs:{"type":"error","dense":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e()]),_c('v-layout',{staticClass:"mt-2"},[_c('div',{staticClass:"tbcard"},[_c('v-card',[_c('v-data-table',{key:_vm.tableKey,attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","search":_vm.e3,"loading":_vm.loading,"server-items-length":_vm.pagination.totalItems,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([(_vm.selected)?{key:"top",fn:function(){return [_c('v-row',[_c('v-layout',{staticClass:"justify-end mt-5 mr-5 ml-5",attrs:{"wrap":""}})],1)]},proxy:true}:null,{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:("del-" + (item.id)),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('span',_vm._g({},Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm._f("ago")(item.createdAt))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))])])]}},{key:"item.input",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:("body-" + (item.id)),attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('span',_vm._g({},Object.assign({}, tooltip)))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.id))])]),_c('v-btn',{staticClass:"secondary--text",attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.copyToClipboard(item.id)}}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:("del-" + (item.id)),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('span',_vm._g({},Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(item.balance.toFixed(4))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.balance))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticClass:"items",attrs:{"top":"","left":"","transition":"v-slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.$store.state.theme === 'light' ? 'secondary--text' : '',attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("delete "+_vm._s(item.id))])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("adjust balance")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" "+_vm._s(/* */)+" ")]),_c('div',{staticClass:"pr-2 pb-2"},[_c('v-layout',{staticClass:"mt-2"},[_c('v-spacer'),_c('span',{staticClass:"text-caption mt-3 mr-4"},[_vm._v("Rows per page:")]),_c('div',{staticClass:"rows-select"},[_c('v-flex',{staticClass:"select",attrs:{"shrink":""}},[_c('v-select',{staticClass:"mb-2",attrs:{"width":"25","items":_vm.itemsPerPageOptions,"dense":"","label":"Rows per page","single-line":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('span',{staticClass:"text-caption mt-3 mr-4 ml-4"},[_vm._v("Total items: "+_vm._s(_vm.pagination.totalItems))]),_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"icon":"","small":"","router":"","disabled":_vm.pagination.previousPageToken == '' ? true : false,"to":{
                    name: 'users',
                    params: {},
                    query: {
                      pageToken: _vm.pagination.previousPageToken,
                    },
                  }}},[_c('v-icon',[_vm._v("navigate_before")])],1),_c('v-btn',{staticClass:"ml-2 mt-2",attrs:{"icon":"","small":"","router":"","disabled":_vm.pagination.nextPageToken == '' ? true : false,"to":{
                    name: 'users',
                    params: {},
                    query: {
                      pageToken: _vm.pagination.nextPageToken,
                    },
                  }}},[_c('v-icon',[_vm._v("navigate_next")])],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }